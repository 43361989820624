import { Zoom } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0073a4",
      dark: "#005072",
      light: "#338fb6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f1f1f1",
      dark: "#e2ecf1",
      light: "#fff",
      contrastText: "#0073a4",
    },
    error: {
      main: "#d32f2f",
      dark: "#c62828",
      light: "#ef5350",
      contrastText: "#fff",
    },
    info: {
      main: "#c1c1c1",
      dark: "#a2a2a2",
      light: "#e4e4e4",
      contrastText: "#fbfbfb",
    },
    warning: {
      main: "#f17940",
      light: "#f6aa7b",
      dark: "#ee5b21",
      contrastText: "#fff",
    },
    success: {
      main: "#08d629",
      dark: "#05951c",
      light: "#39dE53",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Archivo, Arial, sans-serif",
  },
  shape: {
    borderRadius: 11,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px",
          "& .MuiAlert-icon": {
            "& .MuiSvgIcon-root": {
              width: "2em",
              height: "2em",
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const baseColor = ownerState.color === "inherit" ? "primary" : ownerState.color || "primary";
          return ({
            "&.capitalize": {
              textTransform: "capitalize",
            },
            "&.plain": {
              boxShadow: "none",
            },
            "&.lightHover": {
              "&:hover": {
                backgroundColor: theme.palette[baseColor].light,
              },
            },
            "&.lightVariant": {
              backgroundColor: theme.palette[baseColor].light,
              "&:hover": {
                backgroundColor: theme.palette[baseColor].main,
              },
            }
          })
        },
        containedPrimary: ({ theme }) => ({
          borderRadius: 11,
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        }),
        contained: {
          borderRadius: 11,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          cursor: "inherit",
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            backdropFilter: "blur(1px)",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0)",
            backdropFilter: "blur(0px)",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(1px)",
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            backdropFilter: "blur(1px)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "4px 0 5px",
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '&.MuiSnackbar-root': {
            top: 8,
            right: 8,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.borderless": {
            "& fieldset": {
              border: "none"
            },
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.borderless": {
            "& fieldset": {
              border: "none"
            },
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const baseColor = ownerState.color === "inherit" || ownerState.color === "default" ? "primary" : ownerState.color || "primary";
          return ({
            borderRadius: 11,
            "&.contained": {
              backgroundColor: theme.palette[baseColor].main,
              color: theme.palette[baseColor].contrastText,
            },
            "&.lightContained": {
              backgroundColor: theme.palette[baseColor].light,
              color: theme.palette[baseColor].contrastText,
            },
            "&.darkContained": {
              backgroundColor: theme.palette[baseColor].dark,
              color: theme.palette[baseColor].contrastText,
            },

          })
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
      styleOverrides: {
        tooltip: ({ theme }) => {
          return ({
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            borderRadius: 7,
            boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px",
          })
        },
        arrow: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
      },
    },
  }
});

export default theme;
